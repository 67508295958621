<template>
    <div class="pl-bank-wrap">
        <!-- <div class="pl-WeChat-pay pl-flex-start">
            <el-radio v-model="radio" label="1" class="pl-options">&nbsp;</el-radio>
            <img src="./TF-icon/WeChat pay.png" class="pl-WeChat-bank-img">
            <div class="pl-WeChat-bank-text">微信支付</div>  
        </div> -->
        <div class="pl-bank-box">
            <div class="pl-bank-transfer pl-flex-start" v-if="$route.path.indexOf('/bank') > -1">
                <el-radio value="2" label="2" class="pl-options">&nbsp;</el-radio>
                <img src="./TF-icon/bank transfer.png" class="pl-WeChat-bank-img">
                <div class="pl-WeChat-bank-text">银行转账</div>
                <div class="pl-help">
                    <div class="pl-help-img-box">
                        <img src="./TF-icon/help.png" class="pl-help-img">
                    </div>
                    <div class="pl-help-content">
                        <div>“银行转账”是指贵司直接转账到我司的对公账号（公对公账号）鹏隆在收到打款后将及时处理订单</div>
                        <a :href="`/user/order/detail?id=${$route.query.id}`" class="pointer">查看详情</a>
                    </div>
                </div>
            </div>
            <div class="pl-bank-information">
                <div class="pl-account-information">
                    <div class="pl-account-information-title">银行收款账号</div>
                    <div class="pl-service pl-flex-start">
                        <img src="./TF-icon/service.png">
                        <div class="pl-service-one">有疑问？</div>
                        <div class="pl-service-two">联系专属跟单</div>
                    </div>
                    <div class="pl-account-content">账号名称：广东鹏隆自动化科技有限公司<br>开户银行：东莞农村商业银行虎门龙眼支行<br>对公账号：070090190010006073</div>
                    <div class="pl-warm-prompt">
                        <div class="pl-warm-prompt-title">温馨提示：</div>
                        <div class="pl-warm-prompt-content">推荐使用贵司对公银行账户转账！若用个人银行转账后需提供付款证明（盖公章或合同章）给您的专属跟单；</div>
                        <div class="pl-warm-prompt-content">其他支付流程中存在的问题和疑问，可致电鹏隆官方服务热线：<span>0769-85163259</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

</script>
<style lang="scss" scoped>
    a{
        display: block;
        text-decoration: none;
        color: #000;
    }
    .pl-WeChat-pay,.pl-bank-transfer{
        height: 120px;
        background-color: #FFFFFF;
        border: 2px #F4F4F4 solid;
    }
    .pl-WeChat-pay:hover,.pl-bank-box:hover{
        box-shadow: 0px 0px 10px #cbcbcb;
        border: none;
    }
    .pl-options{
        margin-left: 45px;
        margin-right: 0px;
    }
    ::v-deep .el-radio__input.is-checked .el-radio__inner {
        border-color:#1DB3C9;
        background:#1DB3C9;
    }
    .pl-WeChat-bank-img{
        width: 42px;
        margin: 2px 12px 0px 8px;
    }
    .pl-WeChat-bank-text{
        font-family: "Heiti SC","Microsoft YaHei", "Helvetica Neue", Helvetica, sans-serif;
        font-size: 26px;
        font-weight: bold;
        margin-right: 20px;
    }
    .pl-bank-box:hover .pl-bank-transfer{
        border: none;
    }
    .pl-bank-transfer{   
        margin-top: 4px;
    }
    .pl-help{
        position: relative;
    }
    .pl-help:hover .pl-help-content{
        display: flex;
    }
    .pl-help-img-box{
        width: 35px;
        height: 50px;
        position: relative;
        top:-11px;
    }
    .pl-help-img{
        width: 26px;
        position: absolute;
        bottom: 0px;
    }
    .pl-help-content{
        line-height: 20px;
        background-color: #FFFFFF;
        padding: 15px 12px 15px 8px;
        border: 1px #EDEFF5 solid;
        border-radius: 5px;
        box-shadow: 2px 3px 8px #cbcbcb;
        justify-content: flex-start;
        align-items: flex-end;
        position: absolute;
        top: -74px;
        left: -3px;
        display: none;
    }
    .pl-help-content::after{
        content: "";
        width: 0px;
        height: 0px;
        border: 8px solid transparent;
        border-top-color: #FFFFFF;
        border-bottom: none;
        position:absolute;
        bottom: -7px;
    }
    .pl-help-content div{
        width: 310px;
    }
    .pl-help-content a{
        width: 60px;
        color: #1DB3C9;
    }
    .pl-bank-information{
        display: none;
        height: 410px;
        background-color: #FFFFFF;
        position: relative;
    }
    // .pl-bank-box:hover .pl-bank-information{
    //     display: block;
    // }
    .pl-bank-information{
        display: block;
    }
    .pl-account-information{
        width: auto;
        height: 345px;
        background-color: #F8F9FA;
        border: 2px #E9E9E9 solid;
        position: absolute;
        top: 25px;
        left: 50px;
        right: 50px;
    }
    .pl-account-information-title{
        background: #FFFFFF;
        padding: 0px 5px;
        font-family: "Heiti SC","Microsoft YaHei", "Helvetica Neue", Helvetica, sans-serif;
        font-size: 18px;
        font-weight: bold;
        position: absolute;
        top: -10px;
        left: 60px;
    }
    .pl-service{
        position: absolute;
        top: 40px;
        right: 30px;
    }
    .pl-service img{
        height: 16px;
    }
    .pl-service-one,.pl-service-two{
        font-size: 14px;
    }
    .pl-service-one{
        color: #666666;
    }
    .pl-account-content{
        width:280px;
        font-size: 14px;
        line-height: 40px;
        position: absolute;
        top: 100px;
        left: 60px;
    }
    .pl-warm-prompt{
        width: 395px;
        font-size: 14px;
        line-height: 30px;
        background-color: #FFFAF7;
        background-image: url("./TF-icon/exclamation-mark.png");
        background-repeat: no-repeat;
        background-position: 305px 125px;
        border: 3px #FABE8C dashed;
        padding: 12px 23px 23px 37px;
        position: absolute;
        top: 88px;
        right: 30px;
    }
    .pl-warm-prompt-title{
        font-weight: bold;
    }
    .pl-warm-prompt-content{
        color: #ADACAC;
        position: relative;
    }
    .pl-warm-prompt-content::before{
        content: "";
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #F58323;
        position: absolute;
        top: 12px;
        left: -12px;
    }
    .pl-warm-prompt-content span{
        color: #F68323;
    }
</style>
<script>
    export default {
        data () {
        return {
            radio: '1'
        };
        }
    }
</script>